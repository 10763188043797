import { useState, useEffect, useRef, useMemo } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Button, FormControl, InputLabel, ListItemIcon, MenuItem, Select } from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_VisibilityState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { FileDownload, FilterListOff, FileCopy, Visibility } from "@mui/icons-material";
import { OnChangeFn } from "@tanstack/react-table";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { LABELS, ROUTE_PATHS } from "../../../utils/constants";
import { PageTitle, ContainerGrid } from "../../../components";
import { FieldStatusData, RequestData } from "../../../api/type";
import { useAppSelector } from "../../../hooks";
import { requestApi, fieldStatusApi, distributorContactApi } from "../../../api";
import {
  ensureColumnsExist,
  generateFileName,
  getCurrentDateISOEndOfDayTime,
  getCurrentDateISOStartOfDayTime,
  getFullName,
  getNext60DaysISO,
  getPrevious60DaysISO,
} from "../../../utils/helpers";
import { DEFAULT_COLUMNS, initialSortingFilter } from "../contant";
import { RootState } from "../../../store";

interface Categories {
  id: string;
  name: string;
}

const categories: Categories[] = [
  { id: "1", name: "Submitted/Approved" },
  { id: "2", name: "Expiration in next 60 days" },
  { id: "3", name: "Expired in last 60 days" },
];

const ExternalUser = () => {
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  const {
    userDetails: { first_name, last_name, email },
  } = useAppSelector((state: RootState) => state.user);

  const INITIAL_FILTERS = {
    column: [],
    sorting: initialSortingFilter,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const [tableFilter, setTableFilter] = useState<{
    column: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
  }>(INITIAL_FILTERS);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>(DEFAULT_COLUMNS);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});
  const [columnSizingUI, setColumnSizingUI] = useState<MRT_ColumnSizingState>({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const columnVisibilityRef = useRef(columnVisibility);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const columnResizeTimeout = useRef<NodeJS.Timeout | null>(null);

  const [userId, setUserId] = useState<number>();

  const [activeCategory, setActiveCategory] = useState<string>(categories[0].id);
  const [isDropdownChange, setIsDropdownChange] = useState<string>("");

  const [fieldStatus, setFieldStatus] = useState<FieldStatusData>();
  const [isFieldStatusLoaded, setIsFieldStatusLoaded] = useState<boolean>(false);

  const [isShowProgressBars, setIsShowProgressBars] = useState<boolean>(false);

  const [isError, setIsError] = useState(false);

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const saveFieldStatus = async () => {
    const payload = {
      field: { columnVisibility, columnOrder, columnSizing },
      user_email: email,
    };
    try {
      if (fieldStatus?.id) await fieldStatusApi.updateFieldStatus({ ...payload, id: fieldStatus?.id });
      else await fieldStatusApi.createFieldStatus(payload);
    } catch (error) {
      console.error("Failed to save field status:", error);
    }
  };

  const fetchSavedFieldStatus = async () => {
    try {
      const response = await fieldStatusApi.getFieldStatus(email);
      const savedData = response.data.data;

      if (savedData.length) {
        const savedFieldStatus = savedData[0];
        setFieldStatus(savedFieldStatus);

        const { field } = savedFieldStatus;
        const { columnVisibility, columnOrder, columnSizing } = field;

        if (columnVisibility || columnOrder) {
          const { updatedColumnVisibility, updatedColumnOrder } = ensureColumnsExist(
            columnVisibility || {},
            columnOrder || [],
            [{ name: "status", position: 3 }]
          );

          if (columnVisibility) setColumnVisibility(updatedColumnVisibility);
          if (columnOrder) setColumnOrder(updatedColumnOrder);
        }

        if (columnSizing) {
          setColumnSizing(columnSizing);
          setColumnSizingUI(columnSizing);
        }
      }
    } catch (error) {
      console.error("Failed to fetch field status:", error);
    } finally {
      setIsFieldStatusLoaded(true);
    }
  };

  const fetchRequest = async () => {
    try {
      const categoryFilters = getCategoryFilters();
      const filterToAdd = categoryFilters[activeCategory as keyof typeof categoryFilters];
      const updatedFilters = getUpdatedFilters(filterToAdd);
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
      isInitialRender.current = false;
    } catch (err) {
      console.error("Failed to fetch data:", err);
    } finally {
      setIsShowProgressBars(false);
    }
  };

  const fetchUserId = async () => {
    try {
      const filterPayload = [
        { id: "is_obsolete", value: "false" },
        { id: "email", value: email },
      ];
      const { data } = await distributorContactApi.getDistributorByEmail(filterPayload);
      setUserId(data.data[0].id);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const getCategoryFilters = (): Record<string, any> => {
    const isStatusSet = hasStatusColumn(tableFilter);

    const statusFilter = { id: "status", value: [LABELS.SUBMITTED, LABELS.APPROVED] };
    const statusFromTableFilter = tableFilter.column?.find((col) => col.id === "status")?.value || [];

    return {
      "1": isStatusSet ? { id: "status", value: statusFromTableFilter } : statusFilter,
      "2": [
        { id: "valid_to_range", value: { start: getCurrentDateISOStartOfDayTime(), end: getNext60DaysISO() } },
        isStatusSet ? { id: "status", value: statusFromTableFilter } : statusFilter,
      ],
      "3": [
        { id: "valid_to_range", value: { start: getPrevious60DaysISO(), end: getCurrentDateISOEndOfDayTime() } },
        isStatusSet ? { id: "status", value: statusFromTableFilter } : statusFilter,
      ],
    };
  };

  const getUpdatedFilters = (filterToAdd: any) => {
    if (!filterToAdd) return tableFilter;

    const userFilter = [
      { id: "requested_by", value: getFullName({ first_name, last_name }) },
      { id: "requested_by_id", value: userId },
    ];

    return {
      ...tableFilter,
      column: Array.isArray(filterToAdd)
        ? [...tableFilter.column, ...userFilter, ...filterToAdd]
        : [...tableFilter.column, ...userFilter, filterToAdd],
      user_type: "external",
    };
  };

  const hasStatusColumn = (data: { column?: { id: string }[] }): boolean => {
    return data.column?.some((col) => col.id === "status") ?? false;
  };

  const fetchRequestAlsoStore = () => {
    const storeInLocal = { activeCategory, tableFilter };
    localStorage.setItem("requestTable", JSON.stringify(storeInLocal));
    setIsShowProgressBars(true);
    fetchRequest();
  };

  useEffect(() => {
    setIsShowProgressBars(true);
    fetchSavedFieldStatus();
    fetchUserId();
  }, []);

  useEffect(() => {
    if (typeof userId === "number") {
      fetchRequestAlsoStore();
    }
  }, [tableFilter, userId]);

  useEffect(() => {
    if (!isInitialRender.current) {
      fetchRequestAlsoStore();
    }
  }, [isDropdownChange]);

  useEffect(() => {
    const retrievedObject = localStorage.getItem("requestTable");
    if (retrievedObject) {
      const requestTableObj = JSON.parse(retrievedObject);
      setActiveCategory(requestTableObj.activeCategory);
      setTableFilter(requestTableObj.tableFilter);
    }
  }, []);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  useEffect(() => {
    if (
      isFieldStatusLoaded &&
      columnVisibility &&
      Object.keys(columnVisibility).length > 0 &&
      columnOrder &&
      columnOrder.length > 0
    ) {
      saveFieldStatus();
    }
  }, [columnVisibility, columnOrder, columnSizing, isFieldStatusLoaded]);

  useEffect(() => {
    if (columns && columns.length > 0 && Object.keys(columnVisibility).length === 0) {
      const initialVisibility = columns.reduce((acc, column) => {
        const key = column.accessorKey || column.id;
        if (key) acc[key] = true;
        return acc;
      }, {} as MRT_VisibilityState);
      setColumnVisibility(initialVisibility);
    }
  }, [columns]);

  const handleColumnFiltersChange: OnChangeFn<MRT_ColumnFiltersState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      column: typeof updaterOrValue === "function" ? updaterOrValue(prev.column) : updaterOrValue,
    }));
  };

  const handlePaginationChange: OnChangeFn<MRT_PaginationState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      pagination: typeof updaterOrValue === "function" ? updaterOrValue(prev.pagination) : updaterOrValue,
    }));
  };

  const handleSortingChange: OnChangeFn<MRT_SortingState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      sorting: typeof updaterOrValue === "function" ? updaterOrValue(prev.sorting) : updaterOrValue,
    }));
  };

  const handleColumnVisibilityChange: OnChangeFn<MRT_VisibilityState> = (updaterOrValue) => {
    const updatedVisibility = typeof updaterOrValue === "function" ? updaterOrValue(columnVisibility) : updaterOrValue;
    setColumnVisibility(updatedVisibility);
  };

  const handleColumnOrderChange: OnChangeFn<MRT_ColumnOrderState> = (updaterOrValue) => {
    const updatedOrder = typeof updaterOrValue === "function" ? updaterOrValue(columnOrder) : updaterOrValue;
    setColumnOrder(updatedOrder);
  };

  const handleColumnSizingChange: OnChangeFn<MRT_ColumnSizingState> = (updaterOrValue) => {
    const updatedOrder = typeof updaterOrValue === "function" ? updaterOrValue(columnSizing) : updaterOrValue;
    setColumnSizingUI(updatedOrder);
    if (columnResizeTimeout.current) {
      clearTimeout(columnResizeTimeout.current);
    }
    columnResizeTimeout.current = setTimeout(() => {
      setColumnSizing(updatedOrder);
    }, 500);
  };

  useEffect(() => {
    columnVisibilityRef.current = columnVisibility;
  }, [columnVisibility]);

  const handleHideAllClick = () => {
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
  };

  const handleShowAllClick = () => {
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const buttons = Array.from(document.querySelectorAll<HTMLButtonElement>("button.MuiButtonBase-root"));
      buttons.forEach((button) => {
        if (button.textContent?.trim() === "Hide all") {
          button.removeEventListener("click", handleHideAllClick);
          button.addEventListener("click", handleHideAllClick);
        }
        if (button.textContent?.trim() === "Show all") {
          button.removeEventListener("click", handleShowAllClick);
          button.addEventListener("click", handleShowAllClick);
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  const fetchRequestForExport = async () => {
    try {
      const categoryFilters = getCategoryFilters();
      const filterToAdd = categoryFilters[activeCategory as keyof typeof categoryFilters];
      const updatedFilters = getUpdatedFilters(filterToAdd);
      const { data } = await requestApi.getRequestForExport(updatedFilters);
      return data;
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleExportToExcel = async () => {
    try {
      const dataTable = await fetchRequestForExport();
      if (dataTable && dataTable?.data.length > 0) {
        const formatValue = (key: string, value: any) => {
          // Format date values if needed
          if (typeof value === "string" && value.includes("T") && dayjs(value).isValid()) {
            return dayjs.utc(value).format("MM/DD/YYYY");
          }
          return value;
        };

        const mapRowToFormattedData = (row: any) => {
          const formattedRow = columns.reduce((acc, col) => {
            const key = col.accessorKey;
            const header = col.header;

            if (key) {
              acc[header] = formatValue(key, row[key as keyof typeof row]);
            }
            return acc;
          }, {} as Record<string, any>);

          if (row.market_name) formattedRow[LABELS.MARKET_NAME] = row.market_name;
          if (row.application) formattedRow[LABELS.APPLICATION] = row.application;
          if (row.competitor_name) formattedRow[LABELS.COMPETITOR_NAME] = row.competitor_name;
          if (row.requested_by) formattedRow[LABELS.REQUESTED_BY] = row.requested_by;
          if (row.distributor_seller_first_name || row.distributor_seller_last_name) {
            formattedRow["Distributor Seller Name"] = `${row.distributor_seller_first_name ?? ""} ${
              row.distributor_seller_last_name ?? ""
            }`.trim();
          }

          return formattedRow;
        };

        const formattedData = dataTable?.data.map(mapRowToFormattedData);
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const fileName = generateFileName("Request");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error("Failed to export to Excel:", error);
    }
  };

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data: dataTable,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    layoutMode: "grid",
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableRowActions: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    displayColumnDefOptions: { "mrt-row-actions": { size: 80, grow: false } },
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    positionToolbarAlertBanner: "none",
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError ? { color: "error", children: "Error loading data" } : undefined,
    onColumnFiltersChange: handleColumnFiltersChange,
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnSizingChange: handleColumnSizingChange,
    onRowSelectionChange: setRowSelection,
    rowCount: totalRows,
    state: {
      columnFilters: tableFilter.column,
      sorting: tableFilter.sorting,
      pagination: tableFilter.pagination,
      columnVisibility,
      columnOrder,
      columnSizing: columnSizingUI,
      rowSelection,
      showAlertBanner: isError,
      showProgressBars: isShowProgressBars,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
      onDoubleClick: () => navigateToRequestDetail(row.original.id, LABELS.VIEW),
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box>
          <Button onClick={handleExportToExcel} startIcon={<FileDownload />}>
            Export
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              if (tableFilter.column.length) {
                setTableFilter((prev) => ({ ...prev, column: [] }));
              }
            }}
            startIcon={<FilterListOff />}>
            Clear All
          </Button>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={0}
          onClick={() => {
            navigateToRequestDetail(row.original.id, LABELS.CLONE);
            closeMenu();
          }}
          sx={{ m: 0, color: "#4cbcec" }}>
          <ListItemIcon>
            <FileCopy sx={{ color: "#4cbcec" }} />
          </ListItemIcon>
          Clone
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            navigateToRequestDetail(row.original.id, LABELS.VIEW);
            closeMenu();
          }}
          sx={{ m: 0, color: "#1976d2" }}>
          <ListItemIcon>
            <Visibility sx={{ color: "#1976d2" }} />
          </ListItemIcon>
          View
        </MenuItem>,
      ].filter(Boolean);
    },
    muiTablePaperProps: () => ({ className: "DataTableComponentContainer" }),
  });

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={activeCategory}
                label="Select Category"
                onChange={(e) => {
                  setIsDropdownChange(e.target.value.toString());
                  setActiveCategory(e.target.value);
                }}>
                {categories.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </ContainerGrid>
      <ContainerGrid>
        <Grid size={12}>
          <MaterialReactTable table={table} />
        </Grid>
      </ContainerGrid>
    </Box>
  );
};

export default ExternalUser;
