import { Filter, ExportToExcel } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown, generateParamsForExport } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import ProductInformationData, { ApiResponse } from "./types";

const productInformationApi = {
  // getProductInformation(filters: Filter) {
  //   const params = generateParams(filters);
  //   return axios.get<ApiResponse<ProductInformationData>>(API.PRODUCT_INFORMATION, {
  //     params,
  //     headers: { noLoader: "true" },
  //   });
  // },
  getProductInformationForExport(filters: ExportToExcel) {
    const params = generateParamsForExport(filters);
    return axios.get<ApiResponse<ProductInformationData>>(API.PRODUCT_INFORMATION, { params });
  },
  // updateProductInformation(payload: ProductInformationData) {
  //   const { id, ...payloadWithoutId } = payload;
  //   return axios.put<ApiResponse<ProductInformationData>>(`${API.PRODUCT_INFORMATION}/${id}`, payloadWithoutId);
  // },
  createProductInformation(payload: ProductInformationData) {
    return axios.post<ApiResponse<ProductInformationData>>(API.PRODUCT_INFORMATION, payload);
  },
  getProductInformationForDropdown(id: number) {
    const payload = { column: [{ id: "distributor_id", value: id }] } as any;
    const params = generateParamsForDropdown(payload);
    return axios.get<ApiResponse<ProductInformationData>>(API.PRODUCT_INFORMATION, { params });
  },
  getProductInformation(id: number) {
    return axios.get<ApiResponse<ProductInformationData>>(`${API.PRODUCT_INFORMATION}/${id}`);
  },
};

export default productInformationApi;
